<template>
    <div class="online_order type_area">
        <!-- 在线下单 -->
        <div class="filter_area">
            <div class="filter_box">
                <!-- <div class="filter_item">
          <span class="filter_title">产品：</span>
          <div class="item_right">
            <span class="filter_text" :class="{active: filterList[0].name == item.title}" @click="filterClick('产品',item.title,item.id)" v-for="item in productList" :key="item.id">{{item.title}}</span>
          </div>
        </div> -->
                <div class="filter_item brand">
                    <span class="filter_title">品牌：</span>
                    <div class="item_right">
                        <div class="filter_text" :class="{active: filterList[1].name == item.title}" @click="filterClick('品牌',item.title,item.id)" v-for="(item,index) in brandList" :key="item.id">
                            <img v-if="brandMoreText=='展开'?index<11:true" :src="item.image" alt="">
                        </div>
                    </div>
                    <div class="more" @click="brandMoreClick" v-if="brandList.length>11">
                        <span>{{brandMoreText}}</span>
                        <i class="el-icon-caret-bottom icon" id="brandMore"></i>
                    </div>
                </div>
                <div class="filter_item price">
                    <span class="filter_title">价格：</span>
                    <div class="item_right">
                        <span class="filter_text" :class="{active: filterList[2].name == item.title}" @click="filterClick('价格',item.title,item.id)" v-for="(item,index) in priceList" :key="index">{{item.title}}</span>
                        <div class="number_box">
                            <el-input-number v-model="price1" controls-position="right" :min="1"></el-input-number>
                            <span style="margin: 0 2px">~</span>
                            <el-input-number v-model="price2" controls-position="right" :min="price1"></el-input-number>
                            <span class="btn" @click="priceConfirm">确定</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="filter_item">
                    <span class="filter_title">标签：</span>
                    <div class="item_right">
                        <span class="filter_text" :class="{active: filterList[3].name == item.title}" @click="filterClick('标签',item.title,item.id)" v-for="(item,index) in tagsList" :key="index">{{item.title}}</span>
                    </div>
                </div> -->
                <div class="filter_item">
                    <span class="filter_title">楼层：</span>
                    <div class="item_right">
                        <span class="filter_text" :class="{active: filterList[4].name == item.title}" @click="filterClick('楼层',item.title,item.id)" v-for="(item,index) in floorList" :key="index">{{item.title}}</span>
                    </div>
                </div>
                <div class="filter_item filter">
                    <span class="filter_title">筛选：</span>
                    <div class="item_right">
                        <div v-for="(item,index) in filterList" :key="index">
                            <div class="filter_text" v-if="item.name">
                                <span>{{item.name}}</span>
                                <i class="el-icon-close icon" @click="deleteClick(item)"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sieving_tool">
            <div class="tool_left">
                <div style="margin:0 50px;">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane :label="`全部商品(${totalNum})`" name="2"></el-tab-pane>
                        <el-tab-pane :label="`近期已采(${recentYesNum})`" name="1"></el-tab-pane>
                        <el-tab-pane :label="`近期未采(${recentNoNum})`" name="0"></el-tab-pane>
                    </el-tabs>
                </div>
                <div class="sieving_box">
                    <div class="sieving_item" :class="{active: defaultIndex == item.value|| allIndex == item.value||inStockIndex == item.value}" v-for="(item,index) in sievingList" :key="item.value" @click="sievingClick(item.value)">
                        {{item.name}}

                          <img v-if="index==1&&defaultIndex!==2" style="width:13px;height:18px;vertical-align: sub; " src="@/assets/images/orderInfo/black1.png" alt="">
                          <img v-if="index==1&&defaultIndex==2" style="width:13px;height:18px;vertical-align: sub; " src="@/assets/images/orderInfo/white1.png" alt="">
                    </div>
                <!-- <span class="fx" :class="{activefx:sortType=='default'}" @click="changeSortMain('default')">默认排序</span>
                <span class="fx" :class="{activefx:sortType=='sale'}" @click="changeSortMain('sale')">销量</span> -->
                
                  <!-- <span class="fx" :class="{activefx:sortType=='price'}" @click="changeSortMain('price')">价格
            <SortTool :sortOrder="sortData.price" @clickTriangle="(param) => {changeSort(param, 'price')}"/>   
          </span> -->
                </div>
                <div class="sort_habit">
                    <el-checkbox v-model="filterinfo.stock" @change="sortChange">有货</el-checkbox>
                </div>
                <div class="search_box">
                    <el-input placeholder="在结果中搜索（商品名称）" clearable @clear="getGoodsSearch" v-model="searchtValue" @input="searchInput" @keyup.native.enter="getGoodsSearch">
                        <div style="padding: 4px 10px 4px 8px;" slot="append" @click="getGoodsSearch">搜索</div>
                    </el-input>
                </div>
            </div>
            <div class="tool_right">
                <div class="right_item" :class="{active:modeIndex == 0}" @click="modeClick(0)">
                    <div class="wgzs"></div>
                </div>
                <div class="right_item" :class="{active:modeIndex == 1}" @click="modeClick(1)">
                    <div class="lbzs"></div>
                </div>
                <div class="right_item" :class="{active:modeIndex == 2}" @click="modeClick(2)">
                    <div class="bgzs"></div>
                </div>
            </div>
        </div>
        <!-- 商品列表-网格模式 -->
        <div class="goods_box" v-if="modeIndex == 0" v-loading="loading">
            <div class="box_list" v-if="goodsList.length">
                <div class="goods_item" v-for="(item,index) in goodsList" :key="item.goodsId" @click="goodsDetail(item.goodsId)">
                    <!-- 商品图片区 -->
                    <div class="goods_img">
                        <img :src="item.thumbUrl" alt="">
                        <img v-if="item.stock == 0" class="nostock" src="@/assets/images/index/nostock.png" alt="">
                        <div class="tag">
                            <span :style="item1.actType == 2||item1.actType == 4?'':'display: none'" :class="{tj:item1.actType == 2,yh:item1.actType == 4}" v-for="(item1,index1) in item.acts" :key="index1">{{item1.name}}</span>
                        </div>
                        <div v-if="item.isRecent" class="tag">
                            <span :class="{yh:1}" >近期已采</span>
                        </div>
                    </div>
                    <!-- 商品信息区 -->
                    <div class="goods_info">
                        <div class="title">{{item.productName}}</div>
                        <div class="spesc">
                            <img src="@/assets/images/index/goods_gg.svg" alt="">
                            <el-tooltip class="item" effect="dark" :disabled="item.norms&&item.norms.length<30" :content="item.norms" placement="top-start">
                                <span>{{item.norms}}</span>
                            </el-tooltip>
                        </div>
                        <div class="spesc">
                            <img src="@/assets/images/index/goods_cj.svg" alt="">
                            <span>{{item.produceUnit?item.produceUnit:'--'}}</span> 
                        </div>
                        <div class="spesc">
                            <img src="@/assets/images/index/goods_xq.svg" alt="">
                            <span>优于{{item.lastDate?item.lastDate:'--'}}</span>
                        </div>
                        <div style="display:flex;align-items:center;padding-left:10px;margin:10px 0 0px;">
                            <div class="price" v-html="initPrice(item.jiage)"></div>
                            <div v-if="item.jiage!=item.price" style="font-size: 10px;color: #ACACAC;text-decoration-line: line-through;margin-left:5px;">原价{{item.price}}</div>
                        </div>
                        <div class="gross">
                            <span>毛利率 {{item.grossMargin?item.grossMargin:'--'}}</span>
                            <span>零售价 ￥{{(item.retailPrice).toFixed(2)}}</span>
                        </div>
                    </div>
                    <!-- 商品加购收藏区 -->
                    <div class="goods_purchase" @click.stop="">
                        <div class="purchase_top" :style="$route.query.prepareOrderNumber?'justify-content: space-between;':'justify-content: flex-start;'">
                            <div v-if="!$route.query.prepareOrderNumber" class="collect" @click="favoriteClick(item.goodsId,goodsList,index)">
                                <img v-if="item.favorite" src="@/assets/images/index/collect_a.svg" alt="">
                                <img v-else src="@/assets/images/index/collect.svg" alt="">
                            </div>
                            <el-input-number class="number" v-model="item.buyNumber" @change="addNumberChange(item)" :disabled="item.stock == 0" size="small" :min="item | minStep" :step="item | minStep" :precision="0"></el-input-number>
                            <div v-if="item.cartNumber>0&&!$route.query.prepareOrderNumber" class="add exist" :class="{disabled:item.stock == 0}" @click="addPurchase(item)">
                                <span>已在采购车</span>
                            </div>
                            <div v-else class="add" :class="{disabled:item.stock == 0}" @click="addPurchase(item)">
                                <span>{{$route.query.prepareOrderNumber?'加入订单':'加入采购车'}}</span>
                            </div>
                        </div>
                        <span>库存{{item.stock}}</span>
                    </div>
                </div>
            </div>
            <div class="nodata_box" v-else>
                <img src="@/assets/images/index/nodata.png" alt="">
                <span>暂无数据哦~</span>
            </div>
        </div>
        <!-- 商品列表-列表模式 -->
        <div class="list_mode" v-if="modeIndex == 1">
            <div class="goods_list" v-if="goodsList.length">
                <div class="list_item" v-for="(item,index) in goodsList" :key="item.goodsId">
                    <!-- 商品图片 -->
                    <div class="goods_img" @click="goodsDetail(item.goodsId)">
                        <img :src="item.thumbUrl" alt="">
                        <img v-if="item.stock == 0" class="nostock" src="@/assets/images/index/nostock.png" alt="">
                    </div>
                    <!-- 商品信息 -->
                    <div class="goods_info">
                        <div class="title">
                            <div class="tag">
                                <span :style="item1.actType == 2||item1.actType == 4?'':'display: none'" :class="{tj:item1.actType == 2,yh:item1.actType == 4}" v-for="(item1,index1) in item.acts" :key="index1">{{item1.name}}</span>
                            </div>
                            <span>{{item.productName}}</span>
                              <div style="margin-left:10px;" v-if="item.isRecent" class="tag">
                            <span :class="{yh:1}" >近期已采</span>
                        </div>
                        </div>
                        <div class="info_box">
                            <div class="info_item">
                                <div>
                                    <span class="info_name">厂商</span>
                                    <span>{{item.produceUnit?item.produceUnit:'--'}}</span>
                                </div>
                                <div>
                                    <span class="info_name">毛利率</span>
                                    <span class="font_weight_bold" style="color: #666;">{{item.grossMargin?item.grossMargin:'--'}}</span>
                                </div>
                            </div>
                            <div class="info_item">
                                <div>
                                    <span class="info_name">效期</span>
                                    <span>{{item.lastDate?item.lastDate:'--'}}</span>
                                </div>
                                <div>
                                    <span class="info_name">零售价</span>
                                    <span class="font_weight_bold">￥{{(item.retailPrice).toFixed(2)}}</span>
                                </div>
                            </div>
                            <div class="info_item">
                                <div style="width: auto;">
                                    <span class="info_name">规格</span>
                                    <span>{{item.norms}}</span>
                                </div>
                            </div>
                            <div class="info_item">
                                <div>
                                    <span class="info_name">库存</span>
                                    <span>{{item.stock}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="bottom">
                            <div class="price_box" v-html="initPrice(price)"></div>
                            <!-- <div class="yh_box">
                <span class="yh">两件到手价￥5.00</span>
                <img class="sj" src="@/assets/images/order/yh_sj.svg" alt="">
              </div> -->
                        </div>
                    </div>
                    <!-- 商品收藏 -->
                    <div v-if="!$route.query.prepareOrderNumber" class="goods_collect" @click="favoriteClick(item.goodsId,goodsList,index)">
                        <img v-if="item.favorite" src="@/assets/images/index/collect_a.svg" alt="">
                        <img v-else src="@/assets/images/order/collect.svg" alt="">
                    </div>
                    <!-- 商品加购 -->
                    <div class="goods_add">
                        <div class="number_box">
                            <el-input-number class="number" v-model="item.buyNumber" @change="addNumberChange(item)" :disabled="item.stock == 0" size="small" :min="item | minStep" :step="item | minStep" :precision="0"></el-input-number>
                        </div>
                        <div v-if="item.cartNumber>0&&!$route.query.prepareOrderNumber" class="add exist" :class="{disabled:item.stock == 0}" @click="addPurchase(item)">
                            <img src="@/assets/images/order/jg.svg" alt="">
                            <span>已在采购车</span>
                        </div>
                        <div v-else class="add" :class="{disabled:item.stock == 0}" @click="addPurchase(item)">
                            <img v-if="$route.query.prepareOrderNumber" src="@/assets/images/order/order.svg" alt="">
                            <img v-else src="@/assets/images/order/jg.svg" alt="">
                            <span>{{$route.query.prepareOrderNumber?'加入订单':'加入采购车'}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="nodata_box" v-else>
                <img src="@/assets/images/index/nodata.png" alt="">
                <span>暂无数据哦~</span>
            </div>
        </div>
        <!-- 商品列表-表格模式 -->
        <div class="table_mode" v-if="modeIndex == 2">
            <el-table ref="tableList" :data="goodsList" @sort-change="handleSortChange" stripe style="width: 100%">
                <el-table-column prop="date" label="零/整" width="80" align="center">
                    <template slot-scope="scope">
                        <span :style="scope.row.isSeparate==1?'color: #1A9DFF;':'color: #F92B2B'">{{scope.row.isSeparate==1?'整':'零'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="productName" label="品名" sortable="custom" min-width="150" :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        <span class="name" @click="goodsDetail(scope.row.goodsId)">{{scope.row.productName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="produceUnit" label="生产商" sortable="custom" min-width="140" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="norms" label="规格" sortable="custom" min-width="140" :show-overflow-tooltip="true" align="center"></el-table-column>
                <!-- <el-table-column prop="address" label="包装" min-width="70" :show-overflow-tooltip="true" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.Pack[2]">{{scope.row.Pack[2].num}}</span>
            <span v-else>--</span>
          </template>
        </el-table-column> -->
                <el-table-column prop="name" label="中包" min-width="70" :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.Pack[1]">{{scope.row.Pack[1].num}}</span>
                        <span v-else>--</span>
                    </template>
                </el-table-column>
                <el-table-column prop="unit" label="单位" min-width="70" :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.Pack[0]">{{scope.row.Pack[0].units}}</span>
                        <span v-else>--</span>
                    </template>
                </el-table-column>
                <el-table-column prop="lastDate" label="有效期" sortable="custom" width="100" :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.lastDate?scope.row.lastDate:'--'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="stock" label="库存" sortable="custom" min-width="80" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="price" label="价格" sortable="custom" min-width="80" :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.price.toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="加入采购车" width="210" align="center">
                    <template slot-scope="scope">
                        <div class="add_box">
                            <el-input-number v-model="scope.row.buyNumber" @change="addNumberChange(scope.row)" placeholder="请输入" controls-position="right" size="small" :disabled="scope.row.stock == 0" :min="scope.row | minStep" :step="scope.row | minStep" :precision="0"></el-input-number>
                            <div v-if="scope.row.stock!=0">
                                <div class="btn_item add exist" v-if="scope.row.cartNumber>0&&!$route.query.prepareOrderNumber" @click="addPurchase(scope.row)">已在采购车</div>
                                <div class="btn_item add" v-else @click="addPurchase(scope.row)">{{$route.query.prepareOrderNumber?'加入订单':'加入采购车'}}</div>
                            </div>
                            <div v-else class="btn_item active" @click="reserveClick(scope.row)">预定</div>
                        </div>
                    </template>
                </el-table-column>
                <div slot="empty" class="nodata_box">
                    <img src="@/assets/images/index/nodata.png" />
                    <span class="txt">暂无数据哦~</span>
                </div>
            </el-table>
        </div>
        <div class="pagination_box" v-if="total>0">
            <el-pagination background layout="prev, pager, next, sizes, total" :page-sizes="[10, 20, 30, 40]" :page-size.sync="filterinfo.pageSize" :current-page.sync="filterinfo.page" :total="total" @current-change="getGoodsSearch(1)" @size-change="getGoodsSearch(2)">
            </el-pagination>
        </div>
        <!-- 预定弹窗 -->
        <el-dialog class="dialog_box" title="预定数量" :visible.sync="centerDialogVisible" width="262px" center>
            <div class="dialog_main">
                <div class="form_item">
                    <span>缺货提醒</span>
                    <el-radio-group v-model="reserveRadio">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </div>
                <div class="form_item">
                    <span>预定数量</span>
                    <el-input-number style="width: 100%" size="medium" v-model="reserveNumber" placeholder="请输入" :min="0" :precision="0"></el-input-number>
                </div>
            </div>
            <span slot="footer" class="dialog_footer">
                <el-button @click="centerDialogVisible = false" size="medium">取消</el-button>
                <el-button type="primary" @click="reserveConfirm" size="medium">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { getCarNum, getOrderNum } from '@/utils/carNumber'
import SortTool from '@/components/Sort'
export default {
    name: 'OnlineOrder',
    components: {
        SortTool
    },
    data() {
        return {
            loading: false,
            centerDialogVisible: false,
            brandMoreText: '展开',
            searchtValue: '',
            defaultIndex: 1,//默认-最新
            allIndex: null,//整件-拆零
            inStockIndex: null,//有货
            reserveNumber: undefined,
            activeName: '2',
            recentNoNum: 0,
            recentYesNum: 0,
            totalNum: 0,
            reserveRadio: 2,
            modeIndex: 0,//展示模式标识
            filterList: [
                { title: '产品', name: '', id: null },
                { title: '品牌', name: '', id: null },
                { title: '价格', name: '', id: null },
                { title: '标签', name: '', id: null },
                { title: '楼层', name: '', id: null },
                { title: '分类', name: '', id: null },
            ],//筛选列表
            sievingList: [
                { name: '默认', value: 1 },
                { name: '销量', value: 2 },
                // { name: '最新', value: 2 },
                // { name: '整件', value: 3 },
                // { name: '拆零', value: 4 },
                // { name: '有货', value: 5 }
            ],//筛选列表
            productList: [],//产品筛选列表
            brandList: [],//品牌筛选列表
            priceList: [],//价格筛选列表
            tagsList: [],//标签筛选列表
            floorList: [],//楼层筛选列表
            total: 0,
            reserveId: null,//预定商品id
            filterinfo: {
                page: 1,
                pageSize: 20,
                search: '',
                product_code: '',
                product: 0,
                brand: 0,
                price: '',
                tag: 0,
                floor: 0,
                whole: 0,
                stock: false,
                subSearch: "",
                category_id: 0,
                sort: "default",
                orderBy: '',
                recent: 2
            },
            goodsList: [],//搜索商品列表
            checked: false,
            price1: undefined,//价格区间
            price2: undefined,//价格区间
            price: 369.36,
            num: 1,
            num1: undefined,
            sortData: {
                default: 0,
                price: 0,
                sale: 0,
            },
            sortType:''
        };
    },
    filters: {
        minStep(item) {
            let num = 1
            item.Pack.forEach(item1 => {
                if (item1.isPack == 'on') {
                    num = item1.num
                }
            })
            return Number(num)
        }
    },
    watch: {
        // 监听路由参数的变化
        '$route.query.prepareOrderNumber': {
            immediate: false, // 启用初始化监听
            handler(newValue, oldValue) {
                // 当参数变化时，执行数据更新操作
                // if(this.$route.path == '/onlineOrder'){
                //   this.getGoodsSearch()
                // }
                this.getGoodsSearch()
            }
        },
        '$route.query.categoryId': {
            immediate: false, // 启用初始化监听
            handler(newValue, oldValue) {
                // 当参数变化时，执行数据更新操作
                this.filterList[5].id = this.$route.query.categoryId ? this.$route.query.categoryId : ''
                this.filterList[5].name = this.$route.query.categoryName ? this.$route.query.categoryName : ''
                this.getGoodsSearch()
            }
        },
        '$route.query.floorId': {
            immediate: false, // 启用初始化监听
            handler(newValue, oldValue) {
                // 当参数变化时，执行数据更新操作
                this.filterList[4].id = this.$route.query.floorId ? this.$route.query.floorId : ''
                this.filterList[4].name = this.$route.query.floorName ? this.$route.query.floorName : ''
                this.getGoodsSearch()
            }
        },
        '$route.query.keyword': {
            immediate: false, // 启用初始化监听
            handler(newValue, oldValue) {
                // 当参数变化时，执行数据更新操作
                this.getGoodsSearch()
            }
        },
        // '$route.query.searchSelect': {
        //   immediate: false, // 启用初始化监听
        //   handler(newValue, oldValue) {
        //     // 当参数变化时，执行数据更新操作
        //     this.getGoodsSearch()
        //   }
        // }
    },
    mounted() {
        this.resetClick()
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (from.path != '/goodsDetail') {
                vm.resetClick()
            }
        });
    },
    methods: {
        //点击上下箭头选择升降序
        // changeSort(val, type) {
        //     console.log(val,type,'sda')
        //     const defaultData = {
        //         sale: 0,
        //         price: 0,
        //         default: 0,
        //     }
        //     this.sortData = defaultData
        //     this.sortData[`${type}`] = val
        //     console.log(this.sortData,'==================排序1====================')

        // },
        // // 点击文字改变排序
        // changeSortMain(type) {
        //     const typeArr = ['sale', 'price', 'default'];
        //     typeArr.forEach(item => {
        //         if (item !== type) {
        //             this.sortData[`${item}`] = 0
        //         }
        //     })

        //     if (this.sortData[`${type}`] === 2) {
        //         this.sortData[`${type}`] = 0
        //     } else {
        //         this.sortData[`${type}`] += 1
        //     }
        //      this.sortType = type
        //     //  this.orderType = this.sortData[`${type}`]===0 ?'0':this.sortData[`${type}`]===1? 'asc' : 'desc'
        //     //  this.getResourceList(true);

        //      console.log(this.sortData,'==================排序2====================')
        // },
        sortChange(){
       this.getGoodsSearch()
        },
        handleClick(tab, event) {
            this.filterinfo.recent = +this.activeName
            this.getGoodsSearch()
        },
        // 重置数据
        resetClick() {
            // 重置data数据
            Object.assign(this.$data, this.$options.data());
            // 获取用户排序习惯
            // let data = localStorage.getItem('sievingData') ? JSON.parse(localStorage.getItem('sievingData')) : ''
            // if (data) {
            //     this.checked = data.checked
            //     this.defaultIndex = data.defaultIndex
            //     this.allIndex = data.allIndex
            //     this.inStockIndex = data.inStockIndex
            // }
            // 分类跳转
            this.filterList[5].id = this.$route.query.categoryId ? this.$route.query.categoryId : ''
            this.filterList[5].name = this.$route.query.categoryName ? this.$route.query.categoryName : ''
            this.filterList[4].id = this.$route.query.floorId ? this.$route.query.floorId : ''
            this.filterList[4].name = this.$route.query.floorName ? this.$route.query.floorName : ''
            // 获取搜索筛选条件
            this.getSearchOption()
            // 获取默认搜索列表
            this.getGoodsSearch()
        },
        // 获取搜索筛选条件
        getSearchOption() {
            this.$api.pcSearchOption().then((res) => {
                if (res.data.code === 200) {
                    this.productList = res.data.data.product
                    this.brandList = res.data.data.brand
                    this.priceList = res.data.data.price
                    this.tagsList = res.data.data.tags
                    this.floorList = res.data.data.floor
                }
            });
        },
        // 搜索框输入
        searchInput(val) {
            if (!val) this.getGoodsSearch()
        },
        // 获取搜索列表
        getGoodsSearch(num) {
            if (num != 1 && num != 2) {
                this.filterinfo.page = 1
                this.filterinfo.pageSize = 20
            }
            if (num == 2) this.filterinfo.page = 1
            this.filterinfo.product = this.filterList[0].id ? Number(this.filterList[0].id) : 0
            this.filterinfo.brand = this.filterList[1].id ? Number(this.filterList[1].id) : 0
            this.filterinfo.price = this.filterList[2].name ? this.filterList[2].name : ''
            this.filterinfo.tag = this.filterList[3].id ? Number(this.filterList[3].id) : 0
            this.filterinfo.floor = this.filterList[4].id ? Number(this.filterList[4].id) : 0
            // this.filterinfo.floor = this.$route.query.keyword&&this.filterList[4].id&&0
            this.filterinfo.category_id = this.filterList[5].id ? Number(this.filterList[5].id) : 0
            this.filterinfo.whole = this.allIndex == 3 ? 1 : (this.allIndex == 4 ? 2 : 0)
            // this.filterinfo.stock = this.inStockIndex == 5 ? true : false
            this.filterinfo.subSearch = this.searchtValue
            if (this.defaultIndex === 0) {
                this.filterinfo.sort = ''
            } else {
                this.filterinfo.sort = this.defaultIndex == 1 ? 'default' : 'saleNum'
            }
            // 搜索栏跳转
            this.filterinfo.search = this.$route.query.keyword
            // if(this.$route.query.searchSelect == 1){
            //   // 综合
            //   this.filterinfo.search = this.$route.query.keyword
            //   this.filterinfo.product_code = ''
            // }else if(this.$route.query.searchSelect == 2){
            //   // 编码
            //   this.filterinfo.product_code = this.$route.query.keyword
            //   this.filterinfo.search = ''
            // }else{
            //   this.filterinfo.search = ''
            //   this.filterinfo.product_code = ''
            // }
            if (this.$route.query.prepareOrderNumber) this.filterinfo.orderNumber = this.$route.query.prepareOrderNumber
            this.loading = true
            this.$api.pcGoodsSearch(this.filterinfo).then((res) => {
                this.loading = false
                if (res.data.code === 200) {
                    this.goodsList = res.data.data.list
                    this.total = res.data.data.total
                    this.recentYesNum = res.data.data.recentYesNum
                    this.recentNoNum = res.data.data.recentNoNum
                    this.totalNum = res.data.data.totalNum
                } else {
                    // this.$toast(res.data.msg)
                }
            }).catch(error => {
                // this.loading = false
                // this.$toast("请求异常，请稍后重试！");
            });
        },
        // 筛选条件选择
        filterClick(title, name, id) {
            this.filterList.forEach(item => {
                if (item.title == title) {
                    if (item.name == name) {
                        item.name = ''
                        item.id = null
                    } else {
                        item.name = name
                        item.id = id
                    }
                }
            })
            // 获取默认搜索列表
            this.getGoodsSearch()
        },
        // 筛选条件删除
        deleteClick(item) {
            if (item.title == '分类' && this.$route.query.categoryId) {
                this.$router.replace({ path: '/onlineOrder', query: { categoryId: null, categoryName: null } })
                item.name = ''
                item.id = ''
                return
            }
            if (item.title == '楼层' && this.$route.query.floorId) {
                this.$router.replace({ path: '/onlineOrder', query: { floorId: null, floorName: null } })
                item.name = ''
                item.id = ''
                return
            }
            item.name = ''
            item.id = ''
            this.getGoodsSearch()
        },
        // 点击品牌展开按钮
        brandMoreClick() {
            this.brandMoreText = this.brandMoreText == '展开' ? '收起' : '展开'
            // 获取元素
            var element = document.getElementById('brandMore');
            // 设置旋转样式
            if (this.brandMoreText == '展开') {
                element.classList.remove("active");
            } else {
                element.classList.add("active");
            }
        },
        // 记住排序习惯
        checkedChange() {
            if (this.checked) {
                let sievingData = {
                    defaultIndex: this.defaultIndex,
                    allIndex: this.allIndex,
                    inStockIndex: this.inStockIndex,
                    checked: this.checked
                }
                let data = JSON.stringify(sievingData)
                localStorage.setItem('sievingData', data)
            } else {
                localStorage.removeItem('sievingData')
            }
        },
        // 
        modeClick(num) {
            this.modeIndex = num
            if (this.defaultIndex === 0) {
                // 获取用户排序习惯
                let data = localStorage.getItem('sievingData') ? JSON.parse(localStorage.getItem('sievingData')) : ''
                if (data) {
                    this.checked = data.checked
                    this.defaultIndex = data.defaultIndex
                    this.allIndex = data.allIndex
                    this.inStockIndex = data.inStockIndex
                } else {
                    this.defaultIndex = 1
                }
                this.filterinfo.orderBy = ''
                this.getGoodsSearch()
            }
        },
        // 表格模式排序
        handleSortChange({ column, prop, order }) {
            this.defaultIndex = 0
            if (prop == 'productName') prop = 'product_name'
            if (prop == 'produceUnit') prop = 'produce_unit'
            if (prop == 'lastDate') prop = 'last_date'
            if (order == 'ascending') {
                this.filterinfo.orderBy = prop + " asc"
            } else {
                this.filterinfo.orderBy = prop + " desc"
            }
            this.getGoodsSearch()
        },
        // 筛选栏筛选点击
        sievingClick(index) {
            if (index == 1 || index == 2) {
                this.defaultIndex = index
                this.filterinfo.orderBy = ''
                this.$refs.tableList && this.$refs.tableList.clearSort()
            }
            //  else if (index == 3 || index == 4) {
            //     if (this.allIndex == index) {
            //         this.allIndex = null
            //         // 排序习惯存储
            //         // this.checkedChange()
            //         this.getGoodsSearch()
            //         return
            //     }
            //     this.allIndex = index
            // }
            //  else if (index == 5) {
            //     if (this.inStockIndex == index) {
            //         this.inStockIndex = null
            //         // 排序习惯存储
            //         // this.checkedChange()
            //         this.getGoodsSearch()
            //         return
            //     }
            //     this.inStockIndex = index
            // }
            // 排序习惯存储
            // this.checkedChange()
            // 获取默认搜索列表
            this.getGoodsSearch()
        },
        // 点击商品列表预定
        reserveClick(item) {
            this.reserveId = item.goodsId
            this.centerDialogVisible = true
        },
        // 确定预定
        reserveConfirm() {
            if (!this.reserveNumber) return this.$toast('请输入预定数量')
            let params = {
                id: this.reserveId,
                num: this.reserveNumber,
                remind: this.reserveRadio
            }
            this.$api.pcGoodsPredetermine(params).then((res) => {
                this.loading = false
                if (res.data.code === 200) {
                    if (res.data.data.result) {
                        this.$toast('预定成功！')
                    } else {
                        this.$toast('当天已预订！')
                    }
                    this.centerDialogVisible = false
                } else {
                    this.$toast(res.data.msg)
                }
            })
        },
        // 页面跳转
        goodsDetail(id) {
            if (this.$route.query.prepareOrderNumber) {
                this.$router.push('/goodsDetail?goodsId=' + id + '&type=audit&prepareOrderNumber=' + this.$route.query.prepareOrderNumber)
            } else {
                this.$router.push('/goodsDetail?goodsId=' + id)
            }
        },
        // 价格区间确定按钮
        priceConfirm() {
            if (this.price1 === undefined || this.price2 === undefined) return this.$message({ message: '请输入完整的价格区间', type: "warning" })
            let price = this.price1 + "~" + this.price2
            this.filterClick('价格', price)
            this.price1 = undefined
            this.price2 = undefined
        },
        // 收藏点击
        favoriteClick(id, list, index) {
            let params = {
                goodsId: id
            }
            this.$api.pcProductDoFav(params).then((res) => {
                if (res.data.code === 200) {
                    list[index].favorite = list[index].favorite == true ? false : true
                    list = list.filter(item => item)
                    let msg = list[index].favorite ? '收藏成功' : '取消收藏成功'
                    this.$toast(msg);
                }
            });
        },
        // 加购输入框限制
        addNumberChange(item) {
            var number = 1
            item.Pack.forEach(item1 => {
                if (item1.isPack == 'on') {
                    number = item1.num
                }
            })
            if (item.buyNumber % number != 0) {
                this.$toast('加购数量必须为中包装数量倍数！');
                item.buyNumber = number
            }
        },
        // 加入采购车
        addPurchase(item) {
            if (item.stock == 0) {
                this.$toast('该商品即将到货！');
            } else {
                let params = {
                    goodsId: item.goodsId,
                    num: item.buyNumber,
                    actType: 1,
                    actId: 0,
                }
                if (this.$route.query.prepareOrderNumber) params.prepareOrderNumber = this.$route.query.prepareOrderNumber
                this.$api.pcJoinCar(params).then((res) => {
                    if (res.data.code === 200) {
                        this.$toast(res.data.data.message);
                        if (res.data.data.state === 200) {
                            // 成功加入采购车后按钮状态变成已在采购车
                            item.cartNumber = item.buyNumber
                        }
                        if (this.$route.query.prepareOrderNumber) {
                            // 更新采购车数量
                            getOrderNum(this)
                        } else {
                            // 更新采购车数量
                            getCarNum(this)
                        }
                    }
                });
            }
        },
    }
};
</script>
<style lang="scss" scoped>
.online_order {
  .fx{
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 10px;
  }
  .activefx{
    color: #0687fe;
  }
    .filter_area {
        background: #fff;
        padding: 5px 16px;
        margin-top: 14px;
        border-radius: 2px 2px 2px 2px;
  
        .filter_box {
            .filter_item {
                padding: 10px 0;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                font-size: 12px;
                border-bottom: 1px dashed #dfdfdf;

                &:last-of-type {
                    border: none;
                }

                &.brand {
                    // height: 37px;
                    padding: 4px 0;
                    position: relative;
                    .item_right {
                        .filter_text {
                            position: relative;
                            margin-right: 10px;
                            border-radius: 2px 2px 2px 2px;
                            overflow: hidden;
                            border: 1px solid transparent;

                            img {
                                width: 80px;
                                height: 28px;
                                vertical-align: middle;
                            }

                            &:hover {
                                border: 1px solid #1a9dff;
                            }

                            &.active {
                                border: 1px solid #1a9dff;

                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    width: 13px;
                                    height: 14px;
                                    background: url('~@/assets/images/order/brand_a.svg');
                                }
                            }
                        }
                    }

                    .more {
                        position: absolute;
                        right: 0;
                        top: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 54px;
                        height: 24px;
                        box-sizing: border-box;
                        padding-left: 3px;
                        border-radius: 2px 2px 2px 2px;
                        border: 1px solid #e8e8e8;
                        font-size: 12px;
                        color: #999999;
                        cursor: pointer;

                        &:hover {
                            color: #60bbff;
                            border-color: #60bbff;
                            transition: 0.3s;
                        }

                        .icon {
                            font-size: 14px;
                            transition: 0.3s;

                            &.active {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                &.price {
                    padding: 8px 0;
                    .number_box {
                        display: flex;
                        align-items: center;

                        ::v-deep {
                            .el-input-number.is-controls-right
                                .el-input-number__decrease,
                            .el-input-number.is-controls-right
                                .el-input-number__increase {
                                display: none;
                            }
                            .el-input-number.is-controls-right
                                .el-input__inner {
                                padding: 0;
                                font-size: 12px;
                            }
                            .el-input__inner,
                            .el-input-number {
                                width: 40px;
                                height: 20px;
                                line-height: 20px;
                                border-radius: 2px;
                            }
                        }

                        .btn {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 40px;
                            height: 20px;
                            margin-left: 12px;
                            background: #1a9dff;
                            border-radius: 2px 2px 2px 2px;
                            font-size: 12px;
                            color: #ffffff;
                            cursor: pointer;

                            &:hover {
                                opacity: 0.9;
                            }
                        }
                    }
                }

                &.filter {
                    padding: 9px 0;
                    height: 38px;
                    .item_right {
                        .filter_text {
                            padding: 2px 6px;
                            background: #e9f6ff;
                            color: #1a9dff;
                            border-radius: 2px 2px 2px 2px;
                            margin-right: 7px;
                            cursor: auto;

                            .icon {
                                color: #74c3ff;
                                margin-left: 3px;
                                font-weight: 600;
                                font-size: 11px;
                                cursor: pointer;

                                &:hover {
                                    color: #b3deff;
                                }
                            }
                        }
                    }
                }

                .filter_title {
                    flex-shrink: 0;
                    display: inline-block;
                    width: 72px;
                    color: #999999;
                }

                .item_right {
                    width: 1034px;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    .filter_text {
                        display: inline-block;
                        color: #333333;
                        margin-right: 18px;
                        cursor: pointer;

                        &:hover,
                        &.active {
                            color: #1a9dff;
                        }
                    }
                }
            }
        }
    }
    .sieving_tool {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;
        background: #fff;
        margin: 20px 0;

        .tool_left {
            display: flex;
            align-items: center;

            .sieving_box {
                display: flex;
                align-items: center;

                .sieving_item {
                    width: 72px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    font-size: 14px;
                    color: #666666;
                    cursor: pointer;
                    margin-right: 1px;

                    &.active,
                    &.active:hover {
                        background: #1a9dff;
                        color: #fff;
                    }

                    &:hover {
                        color: #1a9dff;
                    }
                }
            }

            .search_box {
                width: 232px;

                ::v-deep {
                    .el-input__inner {
                        border-radius: 100px 0 0 100px;
                        border-color: #ebebeb;
                        height: 26px;
                        line-height: 26px;
                        font-size: 12px;
                        color: #333;
                    }
                    .el-input-group__append {
                        border-radius: 0 100px 100px 0;
                        border-color: #ebebeb;
                        padding: 0;
                        background: #fff;
                        font-size: 12px;
                        color: #333333;
                        cursor: pointer;
                    }
                    .el-input__suffix {
                        top: -6px;
                    }
                }
            }

            .sort_habit {
                margin: 0 38px 0 20px;
            }
        }
        .tool_right {
            display: flex;
            align-items: center;
            padding-right: 12px;

            .right_item {
                padding: 4px;
                cursor: pointer;
                transition: 0.3s;
                &:hover .wgzs,
                &.active .wgzs {
                    background: url('~@/assets/images/order/wgzs_a.svg');
                }
                &:hover .lbzs,
                &.active .lbzs {
                    background: url('~@/assets/images/order/lbzs_a.svg');
                }
                &:hover .bgzs,
                &.active .bgzs {
                    background: url('~@/assets/images/order/bgzs_a.svg');
                }
            }
            .wgzs {
                width: 13px;
                height: 13px;
                background: url('~@/assets/images/order/wgzs.svg');
            }
            .lbzs {
                width: 13px;
                height: 13px;
                background: url('~@/assets/images/order/lbzs.svg');
            }
            .bgzs {
                width: 13px;
                height: 13px;
                background: url('~@/assets/images/order/bgzs.svg');
            }
        }
    }
    .goods_box {
        .box_list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .goods_item {
                position: relative;
                width: 285px;
                height: 440px;
                box-sizing: border-box;
                margin: 0 20px 20px 0;
                background: #ffffff;
                border-radius: 2px 2px 2px 2px;
                cursor: pointer;

                &:hover {
                    .goods_purchase {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                &:nth-of-type(4n) {
                    margin-right: 0;
                }

                .goods_img {
                    position: relative;
                    text-align: center;
                    margin-bottom: 4px;

                    .nostock {
                        width: 132px;
                        height: 132px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        margin: auto;
                    }

                    img {
                        width: 285px;
                        height: 285px;
                        vertical-align: middle;
                    }

                    .tag {
                        position: absolute;
                        left: 10px;
                        bottom: 0;
                        display: flex;
                        align-items: center;

                        span {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 33px;
                            height: 21px;
                            box-sizing: border-box;
                            border-radius: 3px 3px 3px 3px;
                            font-size: 12px;
                            line-height: 0px;
                        }

                        .tj {
                            background: rgba(248, 213, 213, 0.9);
                            border: 1px solid #ffacac;
                            color: #f92b2b;
                            margin-right: 4px;
                            //  width: auto;
                        }
                        .zy {
                            background: rgba(206, 231, 249, 0.9);
                            border: 1px solid #b7dcf8;
                            color: #1a9dff;
                            margin-right: 4px;
                        }
                        .yh {
                            width: auto;
                            padding: 0 6px;
                            background: rgba(248, 240, 223, 0.9);
                            border: 1px solid #fad894;
                            color: #f7a400;
                        }
                    }
                }

                .goods_info {
                    padding: 0 10px;

                    .title {
                        font-weight: bold;
                        font-size: 16px;
                        color: #333333;
                        margin-bottom: 6px;
                        white-space: nowrap; /* 不换行 */
                        overflow: hidden; /* 超出部分隐藏 */
                        text-overflow: ellipsis; /* 溢出部分显示省略号 */
                    }
                    .spesc {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        color: #666666;
                        margin-bottom: 5px;

                        span {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        img {
                            margin-right: 3px;
                        }
                    }
                    .invent {
                        color: #666666;
                    }
                    .price {
                        color: #f34141;
                        font-weight: bold;
                        font-size: 16px;
                        margin-bottom: 3px;
                    }
                    .gross {
                        display: inline-block;
                        padding: 6px 17px 6px 7px;
                        background: #ecf6ff;
                        border-radius: 2px 2px 2px 2px;
                        font-size: 12px;
                        color: #4e6474;
                        line-height: 0px;
                        width: 236px;
                        box-sizing: border-box;

                        span {
                            &:first-of-type::after {
                                content: '';
                                display: inline-block;
                                width: 1px;
                                height: 10px;
                                background: #c4d4e2;
                                margin: 0 6px 0 9px;
                            }
                        }
                    }
                }

                .goods_purchase {
                    opacity: 0;
                    visibility: hidden;
                    transition: 0.5s;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 8px 10px;
                    background: rgba(0, 0, 0, 0.3);
                    font-size: 12px;
                    color: #ffffff;
                    cursor: auto;

                    .purchase_top {
                        display: flex;
                        align-items: center;
                        margin-bottom: 8px;

                        .collect {
                            width: 30px;
                            height: 30px;
                            box-sizing: border-box;
                            margin-right: 25px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #f5f7fa;
                            border-radius: 4px 4px 4px 4px;
                            border: 1px solid #ffffff;
                            cursor: pointer;
                        }
                        .number {
                            width: 108px;
                            height: 30px;
                            margin-right: 14px;
                            margin-top: -3px;

                            ::v-deep {
                                .el-input__inner {
                                    height: 30px;
                                    line-height: 30px;
                                    background: #f5f7fa;
                                    border: 1px solid #fff;
                                    color: #333;
                                    font-weight: 600;
                                    font-size: 15px;
                                }
                                .el-input-number__decrease,
                                .el-input-number__increase {
                                    width: 26px;
                                    top: 0;
                                    height: 28px;
                                    background: #ecedf0;
                                    border: 1px solid #fff;
                                    color: #333;
                                }
                                .el-icon-plus,
                                el-icon-minus {
                                    font-weight: 600;
                                }
                                .el-input-number__decrease.is-disabled,
                                .el-input-number__increase.is-disabled {
                                    background: #e1e4e9;
                                }
                                .el-input__inner {
                                    padding-left: 22px;
                                    padding-right: 22px;
                                }
                            }
                        }

                        .add {
                            width: 86px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: linear-gradient(
                                181deg,
                                #1a9dff 0%,
                                #0687fe 100%
                            );
                            border-radius: 4px 4px 4px 4px;
                            font-size: 14px;
                            color: #ffffff;

                            &:hover {
                                background: linear-gradient(
                                    181deg,
                                    #4daef6 0%,
                                    #0687fe 100%
                                );
                                cursor: pointer;
                                transition: 0.3s;
                            }
                            &.disabled,
                            &.disabled:hover {
                                background: linear-gradient(
                                    181deg,
                                    #86c8f9 0%,
                                    #7cbdf9 100%
                                );
                            }
                            &.exist {
                                background: linear-gradient(
                                    182deg,
                                    #31c19e 0%,
                                    #14ac87 100%
                                );

                                &:hover {
                                    background: linear-gradient(
                                        182deg,
                                        #14ac87 0%,
                                        #31c19e 100%
                                    );
                                    cursor: pointer;
                                    transition: 0.3s;
                                }
                                &.disabled,
                                &.disabled:hover {
                                    background: linear-gradient(
                                        182deg,
                                        #91dac8 0%,
                                        #89d6c3 100%
                                    );
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .list_mode {
        .goods_list {
            .list_item {
                position: relative;
                padding: 13px 16px;
                margin-bottom: 12px;
                background: #fff;
                display: flex;
                align-items: flex-start;

                .goods_img {
                    position: relative;
                    margin-right: 18px;
                    cursor: pointer;

                    .nostock {
                        width: 119px;
                        height: 119px;
                        position: absolute;
                        top: 10px;
                        right: 0;
                        left: 0;
                        margin: 0 auto;
                    }
                    img {
                        width: 150px;
                        height: 150px;
                        vertical-align: middle;
                    }
                }

                .goods_info {
                    .title {
                        display: flex;
                        align-items: center;
                        font-weight: bold;
                        font-size: 16px;
                        color: #333333;
                        margin-bottom: 10px;
                        .tag {
                            display: flex;
                            align-items: center;

                            span {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 30px;
                                height: 17px;
                                box-sizing: border-box;
                                border-radius: 2px;
                                font-size: 10px;
                                line-height: 17px;
                                margin-right: 4px;
                            }

                            .tj {
                                //   width: auto;
                                background: rgba(248, 213, 213, 0.9);
                                border: 1px solid #ffacac;
                                color: #f92b2b;
                            }
                            .zy {
                                background: rgba(206, 231, 249, 0.9);
                                border: 1px solid #b7dcf8;
                                color: #1a9dff;
                            }
                            .yh {
                                width: auto;
                                padding: 0 6px;
                                background: rgba(248, 240, 223, 0.9);
                                border: 1px solid #fad894;
                                color: #f7a400;
                            }
                        }
                    }
                    .info_box {
                        .info_item {
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: #333333;
                            margin-bottom: 3px;

                            & > div {
                                width: 320px;
                                margin-right: 16px;
                            }

                            .info_name {
                                color: #999999;
                                margin-right: 12px;
                            }
                        }
                    }

                    .bottom {
                        display: flex;
                        align-items: center;
                        margin-top: 7px;

                        .price_box {
                            font-size: 20px;
                            color: #f34141;
                            font-weight: 600;
                        }

                        .yh_box {
                            position: relative;
                            margin-left: 10px;
                            padding-bottom: 2px;
                            .yh {
                                width: auto;
                                padding: 2px 6px;
                                background: rgba(248, 240, 223, 0.9);
                                border: 1px solid #fad894;
                                border-radius: 4px;
                                font-size: 12px;
                                color: #f7a400;
                            }
                            .sj {
                                position: absolute;
                                left: -4px;
                                top: 8px;
                            }
                        }
                    }
                }

                .goods_collect {
                    position: absolute;
                    top: 11px;
                    right: 19px;
                    width: 28px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: rgba(0, 0, 0, 0.2);
                    border-radius: 4px 4px 4px 4px;
                    cursor: pointer;
                }
                .goods_add {
                    position: absolute;
                    bottom: 17px;
                    right: 34px;
                    display: flex;
                    align-items: center;

                    .number {
                        width: 117px;
                        height: 40px;
                        margin-right: 31px;
                        margin-top: -3px;

                        ::v-deep {
                            .el-input__inner {
                                height: 40px;
                                line-height: 40px;
                                background: #ffffff;
                                border: 1px solid #f0f0f0;
                                font-size: 18px;
                                color: #333;
                                font-weight: 600;
                            }
                            .el-input-number__decrease,
                            .el-input-number__increase {
                                width: 31px;
                                top: 0;
                                height: 38px;
                                line-height: 38px;
                                background: #f5f7fa;
                                border: 1px solid #f0f0f0;
                                color: #333;
                            }
                            .el-icon-plus,
                            el-icon-minus {
                                font-weight: 600;
                            }
                            .el-input-number__decrease.is-disabled,
                            .el-input-number__increase.is-disabled {
                                background: #e1e4e9;
                            }
                            .el-input__inner {
                                padding-left: 33px;
                                padding-right: 33px;
                            }
                        }
                    }

                    .add {
                        width: 142px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: linear-gradient(
                            182deg,
                            #1a9dff 0%,
                            #0687fe 100%
                        );
                        border-radius: 4px 4px 4px 4px;
                        font-size: 18px;
                        color: #ffffff;

                        img {
                            margin-right: 8px;
                        }

                        &:hover {
                            background: linear-gradient(
                                181deg,
                                #4daef6 0%,
                                #0687fe 100%
                            );
                            cursor: pointer;
                            transition: 0.3s;
                        }
                        &.disabled,
                        &.disabled:hover {
                            background: linear-gradient(
                                181deg,
                                #86c8f9 0%,
                                #7cbdf9 100%
                            );
                        }

                        &.exist {
                            background: linear-gradient(
                                182deg,
                                #31c19e 0%,
                                #14ac87 100%
                            );

                            &:hover {
                                background: linear-gradient(
                                    182deg,
                                    #14ac87 0%,
                                    #31c19e 100%
                                );
                                cursor: pointer;
                                transition: 0.3s;
                            }
                            &.disabled,
                            &.disabled:hover {
                                background: linear-gradient(
                                    182deg,
                                    #91dac8 0%,
                                    #89d6c3 100%
                                );
                            }
                        }
                    }
                }
            }
        }
    }
    .table_mode {
        .name {
            &:hover {
                cursor: pointer;
                color: #1a9dff;
            }
        }
        .add_box {
            display: flex;
            align-items: center;

            ::v-deep {
                .el-input-number.is-controls-right .el-input-number__decrease,
                .el-input-number.is-controls-right .el-input-number__increase {
                    display: none;
                }
                .el-input-number.is-controls-right .el-input__inner {
                    padding: 0;
                    font-size: 18px;
                    color: #333;
                    font-weight: 600;
                }
                .el-input__inner,
                .el-input-number {
                    width: 72px;
                    height: 30px;
                    line-height: 30px;
                    border-radius: 2px;

                    &::placeholder {
                        color: #c7c3c3;
                        font-size: 12px;
                    }
                }
            }

            .btn_item {
                width: 106px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 8px;
                background: linear-gradient(182deg, #1a9dff 0%, #0687fe 100%);
                border-radius: 4px 4px 4px 4px;
                font-size: 18px;
                color: #ffffff;
                cursor: pointer;

                &.add {
                    &:hover {
                        background: linear-gradient(
                            181deg,
                            #4daef6 0%,
                            #0687fe 100%
                        );
                        cursor: pointer;
                        transition: 0.3s;
                    }

                    &.exist {
                        background: linear-gradient(
                            182deg,
                            #31c19e 0%,
                            #14ac87 100%
                        );

                        &:hover {
                            background: linear-gradient(
                                182deg,
                                #14ac87 0%,
                                #31c19e 100%
                            );
                            cursor: pointer;
                            transition: 0.3s;
                        }
                        &.disabled,
                        &.disabled:hover {
                            background: linear-gradient(
                                182deg,
                                #91dac8 0%,
                                #89d6c3 100%
                            );
                        }
                    }
                }

                &.active {
                    background: linear-gradient(
                        182deg,
                        #f7a400 0%,
                        #f6b73d 100%
                    );
                }
            }
        }
    }
}

.dialog_box {
    ::v-deep {
        .el-dialog {
            border-radius: 10px 10px 10px 10px;
        }
        .el-dialog__header {
            padding: 18px 16px;
            font-weight: bold;
            font-size: 18px;
            color: #1a1a1a;
        }
        .el-dialog__body {
            padding: 0 25px 0;
        }
        .el-dialog__footer {
            padding: 4px 25px 20px;
        }
    }
    .dialog_main {
        font-size: 14px;
        color: #1a1a1a;

        .form_item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 10px;

            & > span {
                margin-bottom: 8px;
            }

            ::v-deep {
                .el-input-number .el-input__inner {
                    padding: 0 10px;
                    text-align: left;
                }
                .el-input-number__decrease,
                .el-input-number__increase {
                    display: none;
                }
            }
        }
    }
    .dialog_footer {
        ::v-deep {
            .el-button {
                font-size: 16px;
                padding: 10px 33px;
            }
        }
    }
}
.nodata_box {
    width: 1198px;
}
:deep(.el-tabs__nav-wrap:after) {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px; // 改为0
    background-color: var(--el-border-color-light);
    z-index: var(--el-index-normal);
}
:deep(.el-tabs__item) {
    line-height: 50px;
}
</style>